.custom3-fixed-buttons {
    display: flex;
    justify-content: space-between; /* 左右对齐 */
    align-items: center;
    width: 100%; /* 占满整个容器宽度 */
    margin-top: 20px;
    padding: 0 20px; /* 为按钮添加左右内边距 */
}

.custom3-left-fixed-button {
    cursor: pointer;
    width: 250px; /* 调整按钮宽度 */
    height: auto;
    margin-left: 0; /* 去除默认的左边距 */
}

.custom3-right-fixed-button {
    cursor: pointer;
    width: 250px; /* 调整按钮宽度 */
    height: auto;
    margin-right: 0; /* 去除默认的右边距 */
}
