/* src/components/Login.css */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7;
  }
  
  .login-container h2 {
    margin-bottom: 20px;
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-container input {
    margin-bottom: 10px;
    padding: 10px;
    width: 200px;
  }
  
  .login-container button {
    padding: 10px;
    width: 220px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #45a049;
  }
  