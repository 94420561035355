.hero {
    position: relative;
    color: white;
    text-align: center;
    padding: 50px 20px;
    height: 500px; 
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
}

/* 调整背景遮罩的透明度 */
.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* 调整为更浅的透明度 */
    z-index: 0; 
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    z-index: -1; 
}

.hero-text-container {
    z-index: 1; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    background: rgba(232, 224, 224, 0.2); /* 调整文字区域的背景透明度 */
    border-radius: 10px;
}

/* 增大标题的字体 */
.hero-title {
    font-size: 45px; /* 调整标题字体大小 */
    font-weight: bold;
    margin-bottom: 20px;
    animation: fadeIn 3s ease-in-out;
    font-family: 'Arial', sans-serif;
    line-height: 1.2; /* 减少行间距 */
}

/* 增大描述文字的字体 */
.hero-description {
    font-size: 28px; /* 调整描述字体大小 */
    line-height: 1.5;
    max-width: 700px; /* 增加最大宽度 */
    padding: 0 20px;
    animation: fadeIn 3s ease-in-out;
    font-family: 'Arial', sans-serif;
}

/* 按钮样式 */
.hero-button {
    display: inline-block;
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    background-color: #8c6bae; /* 按钮背景色 */
    border: none;
    border-radius: 5px;
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none; /* 移除下划线 */
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* 鼠标悬停时按钮效果 */
.hero-button:hover {
    background-color: #6c63ff;
    transform: translateY(-3px);
}

/* 按钮点击时效果 */
.hero-button:active {
    background-color: #55517a;
    transform: translateY(1px);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
