.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    position: relative;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  .close-button {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #d8d5d5;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #7f8c8d;
    transition: box-shadow 0.3s ease;
  }
  .close-button:hover {
    color: #34495e;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }