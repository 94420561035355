/* src/components/EnterItemsManually.css */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center; /* Center align items vertically */
    margin-top: 20px;
    animation: slideIn 0.5s ease-in-out;
    gap: 10px; /* Add space between elements */
}

.form-container input[type="text"],
.form-container input[type="number"], /* Added number input styling */
.form-container input[type="date"],
.form-container select {
    padding: 10px;
    margin: 0 5px;
    border: 2px solid #9370DB;
    border-radius: 5px;
    transition: all 0.3s ease;
    outline: none;
    width: 150px; /* Consistent width for inputs */
    box-sizing: border-box; /* Include padding and border in width */
}

.form-container input[type="text"]:focus,
.form-container input[type="number"]:focus, /* Added focus styling for number input */
.form-container input[type="date"]:focus,
.form-container select:focus {
    border-color: #805DBD;
    box-shadow: 0 0 10px #805DBD;
}

.form-container .quantity-container { /* Flex container for quantity and unit */
    display: flex;
    align-items: center;
}

.form-container .quantity-container input[type="number"] {
    width: 80px; /* Smaller width for quantity */
    margin-right: 5px; /* Spacing between quantity input and select */
}

.form-container .quantity-container select {
    width: 70px; /* Smaller width for unit select */
}

.form-container button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    background-color: #9370DB;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.form-container button:hover {
    background-color: #805DBD;
    transform: scale(1.05);
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
