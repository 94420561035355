.introduction {
    background-color: #f4e5f7; /* Adjust the background color as needed */
    padding: 30px;
    margin: 40px auto;
    max-width: 900px;
    border-radius: 16px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
}
 
.introduction h2 {
    color: #000; /* Black color for the title */
    font-family: 'Arial', sans-serif; /* Use Arial for a cleaner look */
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: bold;
}
 
.images-container {
    display: flex;
    justify-content: space-around; /* Space around the images */
    align-items: center;
}
 
.image-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 15px;
}
 
.image-icon {
    width: 120px; /* Set image size */
    height: 120px;
    transition: transform 0.3s ease;
}
 
.image-item p {
    margin-top: 15px;
    color: #333;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    font-weight: bold; /* Make the text bold */
}
 
.image-item:hover .image-icon {
    transform: scale(1.1); /* Enlarge image on hover */
}