.help-council-container {
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 8px;
    text-align: center;
}
 
.help-council-container h2 {
    font-size: 24px;
    color: #f8f6f6;
    margin-bottom: 20px;
    background-color: #8c6bae;
    border-radius: 25px;
    padding: 8px 16px;
    display: inline-block;
}
 
.help-council-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}
 
.help-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
.help-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
 
.help-image {
    width: 100%;
    height: auto;
    border-radius: 6px;
    margin-bottom: 10px;
    object-fit: cover;
}
 
.help-image-compost {
    width: 100%;
    height: 54%;
    border-radius: 8px;
    margin-bottom: -20px;
    object-fit: cover;
    justify-content: center;
}
 
.help-image-center {
    width: 80%; /* Adjust size as per your needs */
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
 
.help-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
.help-content h3 {
    font-size: 18px;
    margin-bottom: 8px;
    color: #f8f6f6;
    background-color: #8c6bae;
    border-radius: 25px;
    padding: 8px 16px;
    text-align: center;
    display: inline-block;
}
 
.help-content p {
    font-size: 16px;
    color: #666;
    margin-bottom: 8px;
    line-height: 1.4;
}
 
.small-card {
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.help-content.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
 
 
 
 
/* Hover effect to enlarge the entire button */
.center-title:hover {
    transform: scale(1.05); /* Enlarge the button by 10% */
    background-color: #7a5b9d; /* Change background color on hover */
}
 
/* Styling for the "See More Info" text */
.center-title {
    color: #fff;
    font-size: 18px;
}
 
 
 
/* Popup Styles */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
}
 
.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    max-width: 400px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: auto;
}
 
.popup-image {
    width: 100%;
    max-width: 300px; /* Ensure the image isn't too wide */
    height: auto;
    border-radius: 8px;
}
 
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #333;
    cursor: pointer;
}
 