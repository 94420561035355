body {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* 页面至少占满整个视口高度 */
    margin: 0; /* 移除默认边距 */
}

main {
    flex: 1; /* 主内容区扩展以填充空间 */
}

.footer-decoration {
    width: 100%;
    height: 40px;
    background-color: #8c6bae; /* 紫色背景 */
    margin-top: auto; /* 将其推到页面底部 */
}
