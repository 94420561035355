/* RecommendedList.css */

.recommended-list {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .list-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .list-item:last-child {
    border-bottom: none;
  }
  
  .item-text {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  .edit-form {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .edit-input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .quantity-input {
    width: 60px;
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .unit-select {
    width: 80px;
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin-left: 5px;
    color: #555;
    transition: color 0.3s ease;
  }
  
  .icon-button:hover {
    color: #1976d2;
  }
  
  .save-icon {
    color: #4caf50;
  }
  
  .cancel-icon {
    color: #f44336;
  }