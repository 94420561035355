.query-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px auto;
    gap: 12px;
    max-width: 500px;
}

.query-input {
    flex: 1;
}

.query-input .MuiInputBase-root {
    height: 48px;
}

/* Updated .query-button styles */
.query-button {
    height: 48px;
    padding: 6px 16px;
    font-size: 0.875rem;
    background-color: #8c6bae !important; /* New background color */
    color: #fff; /* New text color */
    border: none;
    border-radius: 4px; /* Optional: Adds rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}


