.info-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    text-align: center;
  }
  
  .info-container h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .info-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .image-container {
    margin-bottom: 20px;
    background-color: #e0e0e0;
    padding: 20px;
    border-radius: 8px;
  }
  
  .impact-image {
    max-width: 100%; /* 将图片宽度设置为容器宽度的 80% */
    max-height: 400px; /* 设置图片的最大高度为 400px */
    height: auto;
    width: auto;
    object-fit: contain; /* 确保图片在调整大小时保持比例 */
}

  
  .text-content {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
    text-align: left;
    max-width: 800px;
  }
  